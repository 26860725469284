<template>
<!-- Moved o365-data-table to grid body for column layout side panele to render correctly-->
    <div class="o365-data-grid  o365-root row-container bg-body" ref="containerRef" :id="dataGridControl.id"
        :class="{
        'o365-data-grid-no-cell-borders': !renderCellBorders,
        'o365-data-grid-lookup': isLookup,
        'no-right-pinned-offset': disableRightPinnedOffset
        }">

         <div class="o365-column-definitions d-none">
            <slot></slot>
        </div>

        <BodyWrapper :disabled="hideGridMenu">
            <GridSidePanel v-if="dataGridControl.leftSidepanel && !hideGridMenu" :gridRef="dataGridControl" :containerHasHeader="containerHasHeader" ref="gridMenu" :disableGridMenuPersist="disableGridMenuPersist"
                :initialVisible="!collapseGridMenu && !isTouch" :tabs="menuTabs" :iframeSrc="detailIframe" :initialWidth="isTouch ? '30%' : initialMenuWidth" leftSidepanel
                :groupByFolders="groupByFolders" :detailTabTitle="detailTabTitle" :maxWidth="isTouch ? '100%' : gridMenuMaxWidth" :minWidth="isTouch ? '20%' : gridMenuMinWidth" :messageChannelId="detailMessageChannelId" :messageChannelFunctions="detailMessageChannelFunctions">
                <template #filterBottom>
                        <!-- @slot 
                            @ignore -->
                    <slot name="setupFilterBottom"></slot>
                </template>
                <template v-if="$slots.detailTab" #detailTab>
                    <slot name="detailTab"></slot>
                </template>
                <template v-for="tab in menuTabs" v-slot:[`tab(${tab.id})`]>
                        <!-- @slot 
                            @ignore -->
                        <slot :name="`menu-tab(${tab.id})`"></slot>
                </template>
                <template v-if="$slots.detailActions" #detailActions>
                    <slot name="detailActions"></slot>
                </template>
            </GridSidePanel>
            <BodyWrapper :disabled="hideGridMenu">
                <GridSidePanelTabs v-if="dataGridControl.leftSidepanel && !hideGridMenu" left :detailTabTitle="detailTabTitle" />

                <div class="o365-grid-body o365-data-table" :class="`grid-${girdUID}`" ref="gridBody">

                    <ErrorBoundry v-if="containerHasHeader" type="span" uiTitleMessage="Am unhandled error has occured when trying to render this card header"
                        uiMessage="Grid Header Render Error" :consoleMessage="`Error encountered when trying to render grid cardheader: ${dataGridControl?.id}`">
                        <div class="o365-card-header hstack pt-2">
                                <h4 v-if="headerTitle" class="mb-0 p-2 me-2">{{$t(headerTitle)}}</h4>
                                <slot name="cardheader" :enable="editMode"></slot>
                        </div>
                    </ErrorBoundry>

                    <ODataGridHeader v-if="!noHeader" v-model="dataColumns.columns" :data-grid-control="dataGridControl"
                        :filter-row="!disableFilterRow" :containerRef="containerRef" :setViewPortWidth="setViewPortWidth" :showNewRecordButton="false"
                        :hasNewRecords="false" :gridMenu="gridMenu" :hideGridMenu="hideGridMenu">
                    </ODataGridHeader>

                    <div class="o365-body-center-viewport o365-body-center-cols-viewport" ref="viewportRef">
                        <Overlay v-if="dataObject && dataObject.state.isLoading" :style="{top: viewportRef?.scrollTop+'px'}"/>
                        <div ref="rowsContainerRef" class="o365-body-cols" :style="[{'width': columnsWidth + dataColumns.unusedWidth + 'px', 'min-width': columnsWidth + dataColumns.unusedWidth + 'px'}]" data-o365-container="G">
                            <template v-for="(row, rowIndex) in data" :key="rowIndex">
                              <DataGridBodyRow :row="row" :rowIndex="rowIndex" class="col-container"
                                :rowClass="row.item ? computedRowClass(row.item) : undefined" :rowStyle="row.item ? computedRowStyle(row.item) : undefined" 
                                :getSelectionClass="getSelectionClass" isTable
                                :activeEditLocation="activeEditCell" :activeRows="activeRows"
                                :updateEditorRef="dataGridControl.updateEditorRef" container="G" 
                                :viewportRef="viewportRef">
                                <template v-if="$slots.rowOverlay" #overlay="{row}">
                                    <slot name="rowOverlay" :row="row"></slot>
                                </template>
                            </DataGridBodyRow>
                            </template>

                            <!-- <div v-if="hasUncomittedRows" class="w-100 py-2 ps-2"> -->
                                <!-- <ODropdown> -->
                                    <!-- <template #default="scope"> -->
                                        <!-- <button :disabled="isCommitting" class="btn btn-sm btn-outline-primary" :class="{'dropdown-toggle': !isCommitting}" :ref="scope.target" @click="() => scope.open()"> -->
                                            <!-- {{$t('Commit pasted rows')}} -->
                                        <!-- <div v-if="isCommitting" class="spinner-border spinner-border-sm ms-2" role="status"> -->
                                                <!-- <span class="visually-hidden">Loading...</span> -->
                                            <!-- </div> -->
                                        <!-- </button> -->
                                    <!-- </template> -->
                                    <!-- <template #dropdown="scope"> -->
                                        <!-- <div class="dropdown-menu show" :ref="scope.container"> -->
                                            <!-- <button class="dropdown-item" :title="$t('Adds rows one by one (each row will send a separate save request)')" --> 
                                                <!-- @click="() => { savePastedRows(false); scope.close();}" type="button">{{$t('One By One')}}</button> -->
                                            <!-- <button class="dropdown-item" :title="$t('Adds all rows simultaneously. If a row fails, the import will be rolled back and no rows will be added)')" --> 
                                                <!-- @click="() => { savePastedRows(true); scope.close()}" type="button">{{ $t('Bulk') }}</button> -->
                                        <!-- </div> -->
                                    <!-- </template> -->
                                <!-- </ODropdown> -->
                                <!-- <button class="btn btn-sm btn-outline-primary ms-2" @click="clearPastedRows"> -->
                                    <!-- {{$t('Clear pasted rows')}} -->
                                <!-- </button> -->
                            <!-- </div> -->
                            
                            <template v-if="hasNewRecords">
                                <div style="display: content;" data-o365-container="N">
                                    <template v-for="(row, rowIndex) in batchData" :key="rowIndex">
                                            <DataGridBodyRow :row="row" :rowIndex="rowIndex" class="col-container"
                                                :rowClass="row.item ? computedRowClass(row.item) : undefined" :rowStyle="row.item ? computedRowStyle(row.item) : undefined" 
                                                :getSelectionClass="(x,y) => getSelectionClass(x,y,'N')"
                                                :activeEditLocation="activeEditCell" isTable
                                                :updateEditorRef="dataGridControl.updateEditorRef" container="N" 
                                                :viewportRef="viewportRef" :activeRows="activeRows">
                                                <template v-if="$slots.rowOverlay" #overlay="{row}">
                                                    <slot name="rowOverlay" :row="row"></slot>
                                                </template>
                                            </DataGridBodyRow>
                                    </template>
                                </div>
                                <!-- <AfterPasteDialog v-if="hasUncomittedRows || isCommitting" v-model:isCommitting="isCommitting" :dataObject="dataGridControl.dataObject" :clearPastedRows="clearPastedRows" /> -->
                            </template>
                        </div>
                    </div>

                    <OGridContextMenu v-if="!disableNavigation" ref="contextMenuRef">
                        <template #top="scope">
                            <!-- @slot
                            @description Top part of the context menu -->
                            <slot name="contextmenuTop" :column="scope.column" :row="scope.row" :close="scope.close"></slot>
                        </template>
                        <template v-if="$slots.contextmenu" #default="scope">
                            <slot name="contextmenu" :column="scope.column" :row="scope.row" :close="scope.close"></slot>
                        </template>
                        <template #bottom="scope">
                            <!-- @slot
                            @description Bottom part of the context menu -->
                            <slot name="contextmenuBottom" :column="scope.column" :row="scope.row" :close="scope.close"></slot>
                        </template>
                    </OGridContextMenu>

                    <ODataGridFooter v-if="showSummaryRow" :dataGridControl="dataGridControl"/>

                    <div v-if="props.dataObject && props.dataObject.state.isNextPageLoading" class="d-flex w-100 position-absolute bg-body" :style="{bottom: showWidthScrollbar ? '14px' : '0px'}">
                        <div class="progress w-100" style="height: 8px;">
                            <div class="progress-bar indeterminate-progress" role="progressbar"></div>
                        </div>
                    </div>

                    <div ref="widthScrollerRef" class="o365-body-horizontal-scroll" style="display:none; height: 14px; max-height: 14px; min-height: 14px; width: 100%;">
                        <div class="o365-body-horizontal-scroll-left-spacer" :style="{ 'min-width': dataColumns.leftPinnedWidth + 'px' }"></div>
                        <div class="o365-body-horizontal-scroll-viewport" :style="{ 'width': viewPortWidth + 'px' }">
                            <div class="o365-body-horizontal-scroll-container" style="height: 14px; max-height: 14px; min-height: 14px;"  
                                :style="[{ 'width': dataColumns.centerWidth + 'px', 'left': dataColumns.leftPinnedWidth + 'px' }]"></div>
                        </div>
                        <div class="o365-body-horizontal-scroll-left-spacer" :style="{ 'min-width': dataColumns.rightPinnedWidth + 'px' }"></div>
                    </div>
                </div>

                <GridSidePanelTabs v-if="!dataGridControl.leftSidepanel && !hideGridMenu" :detailTabTitle="detailTabTitle" />
            </BodyWrapper>
            
            <GridSidePanel v-if="!dataGridControl.leftSidepanel && !hideGridMenu" :gridRef="dataGridControl" :containerHasHeader="containerHasHeader" ref="gridMenu" 
                :initial-visible="!collapseGridMenu && !isTouch" :tabs="menuTabs" :iframeSrc="detailIframe" :initialWidth="initialMenuWidth"
                :groupByFolders="groupByFolders" :detailTabTitle="detailTabTitle" :maxWidth="gridMenuMaxWidth" :minWidth="gridMenuMinWidth" :messageChannelId="detailMessageChannelId" :messageChannelFunctions="detailMessageChannelFunctions">
                <template #filterBottom>
                        <!-- @slot 
                            @ignore -->
                    <slot name="setupFilterBottom"></slot>
                </template>
                <template v-if="$slots.detailTab" #detailTab>
                    <slot name="detailTab"></slot>
                </template>
                <template v-for="tab in menuTabs" v-slot:[`tab(${tab.id})`]>
                        <!-- @slot 
                            @ignore -->
                        <slot :name="`menu-tab(${tab.id})`"></slot>
                </template>
                <template v-if="$slots.detailActions" #detailActions>
                    <slot name="detailActions"></slot>
                </template>
            </GridSidePanel>
        </BodyWrapper>

        <ODataGridStatusBar v-if="!noFooter" :data-object="dataObject" :dataGridControl="dataGridControl">
            <slot name="statusbar">
                <OInfoItems :data-object="dataObject">
                    <template #recordcount>
                        <div v-if="dataObject?.hasPagedData && dataObject.pagedData.enabled" class="px-2 d-flex" style="white-space: nowrap;">
                            <Paginator :dataObject="dataObject" disablePageSizeDropdown/>
                        </div>
                        <div v-else class="px-2 d-flex" style="white-space: nowrap;">
                            <div v-if="dataObject?.state.isLoading" class="spinner-border spinner-border-sm ms-1 align-self-center" role="status">
                                <span class="sr-only"></span>
                            </div>
                            <b v-else class="align-self-center">
                                <span v-if="dataObject?.current?.index !== undefined" :title="$t('Current Row')">{{$formatNumber(dataObject.current?.index+1, '1 234')}} / </span>
                                <span :title="$t('Loaded Rows')">{{$formatNumber(dataObject?.data.length, '1 234')}}</span>
                            </b>
                        </div>
                    </template>
                </OInfoItems>
                <button v-if="dataObject?.allowInsert && !dynamicLoading" class="btn btn-link btn-sm" 
                    :title="$t('Create a new row at the end')" @click="createNew">{{$t('Create New')}}</button>
            </slot>
        </ODataGridStatusBar>

    </div>
</template>

<script lang="ts">
import type { DataObject, DataItemModel } from 'o365-dataobject';

export interface IProps {
    dataObject?: DataObject,
    data?: Partial<{ index: number }>[],
    /** Columns passed as an object instead of slots */
    columns?: DataColumnOptions[],
    /** String, dynamic class object or function that will be bound to the row class property. The current row is provided to the function as an argument */
    rowClass?: any,
    /** String, dynamic class object or function that will be bound to the row style property. The current row is provided to the function as an argument */
    rowStyle?: any,
    /** Title for the grid shown in the header. Must be provided if you want to use #cardheader slot. */
    headerTitle?: string,
    /**
    * The url to be used in the details iframe tab
    * @example `${site.oldGenUrl}/workflow-item?ID=${dsItems.current.ID}&HideNav=true`
    */
    detailIframe?: string,
    useDetailLoadingMessage?: boolean,
    /** Optional id to enable the message channel on the detail iframe  */
    detailMessageChannelId?: string,
    /** Map of functions callable by the iframe message channel */
    detailMessageChannelFunctions?: any,
    /** The label used on the detail iframe tab */
    detailTabTitle?: string,
    /** The initial width on the sidepanel menu */
    initialMenuWidth?: string,
    /** Hide the status bar of this grid */
    noFooter?: boolean
    /** When set to true will not render header */
    noHeader?: boolean,
    /** When set to true will not render header row but will still render the header container */
    noHeaderRow?: boolean,
    /** Enables word wrapping for the header row */
    multilineHeader?: boolean,
    /** When set to true will not render the select all checkbox in the header */
    disableSelectAll?: boolean,
    /** When set to true will not render multi-select column */
    hideMultiselectColumn?: boolean,
    /** When set to true will not render action column */
    hideActionColumn?: boolean,
    /** When set to true will not render system column (current row indicator) */
    hideSystemColumn?: boolean,
    /** When set to true will stylize the active (current) row */
    activeRows?: boolean,
    /** When set to true will render ImportData */
    importData?: boolean,
    /** When set to true will render ImportData */
    importDataBatch?: boolean,
    /**  Pass props in object form to o-365=data-import library */
    importDataProps?: object,

    /** When set to true will skip rendering of the new record rows */
    hideNewRecords?: boolean,
    /** When set to `true` will not render the grid sidepanel menu */
    hideGridMenu?: boolean,
    /** When set to `true` the grid setup menu will be initially collapsed */
    collapseGridMenu?: boolean,
    /** Optional max-width setting for the grid menu (in px or %) */
    gridMenuMaxWidth?: string
    /** Optional min-width setting for the grid menu (in px or %) */
    gridMenuMinWidth?: string
    /** When set to `true` will disable grid navigation features */
    disableNavigation?: boolean,
    /** When set to 'true' will disable sorting array data from grid */
    disableSorting?: boolean,
    /** Select list will contain only visible grid columns and sort order columns set on data object */
    onDemandFields?: boolean,
    /** When set to true the grid will load the dataobject after mount */
    loadDataObject?: boolean
    // /** Use delete confirm for delete actions. Is true by default */
    // disableDeleteConfirm?: boolean,
    /** Use soft delete for ActionDelete in grid */
    softDelete?: boolean,
    /** When set to true will not render filter row */
    disableFilterRow?: boolean,
    /** If provided will filter automatically when typing in filter cells with the debounce value. If provided value is false, then no automatic debouncing is performed. */
    autoFilterDebounce?: number | false,
    /** Use group by folders */
    groupByFolders?: boolean,
    /** An array of initial field filters. For example `['Title', {name:'StatusCode', distinct:'StatusCode'}]` */
    fieldFilters?: Array<string | { name: string, distinct: string }>,
    /**
    * An array of custom tab definitions for the grid sidemenu details tab
    * @example [
    *   { title: 'Custom Tab', id: 'tab1', iconClass: 'bi bi-1-square-fill', component: MyTabComponent}
    * ]
    */
    menuTabs?: Record<string, any>[],
     /** Disable dynamic loading */
    disableDynamicLoading?: boolean,
    /**
     * This value is true by default. To disable it instead of passing `:dynamicLoading="false"`
     * please use disableDynamicLoading prop instead.   
     * @deprecated
     */
    dynamicLoading?: boolean,
    /** Override create new record function */
    createNewOverrideFn?: Function,
    /**
    * Override the row click handler, when provided will not set current index
    * @param {DataItemModel} row - the row that was clicked on
    * @param {MouseEvent} e - the click event 
    * @example (row, e) => dsTest.setCurrentIndex(row.index)
    */
    rowclickhandler?: Function,
    /** Returns grid control ref immediately after creation */
    eagerGridControl?: Function,
    /** @ignore */
    isLookup?: boolean,
    /** @ignore */
    useLeftColumnSizers?: boolean,
    /** @ignore */
    menuTabContainerClass?: string,
    /** Optional object for overriding some grid features */
    gridApi?: {
        /** Override setCurrentIndex calls in the grid */
        setCurrentIndex?: (pIndex: number) => void;
        /** Override load function calls in the grid */
        load?: () => Promise<void>;
        /** Override save function calls in the grid */
        save?: (pIndex: number) => Promise<void>;
        /** Function for data objectless grids used to create new empty items */
        createNew?: () => Partial<DataItemModel>;
    },
    disableColumnMove?: boolean;
    /** Options related to grid context menu */
    contextMenu?: {
        /**
         * Optional funcction to manipulate values used by 'Filter By Selection' and 
         * 'Filter By Excluding Selection'. Used when diffrent filter fields are used from the main field.
         */
        resolveFilterValues?: (pRow: Record<string, any>) => Record<string, any>;
    },
    /** Message object that will be posted to the detail iframe whenever it changes  */
    detailMessage?: any
    /** Api object for overriding various new records functionalities */
    newRecordsApi?: {
        focusFirstEditableCell?: (pGridControl: DataGridControl) => void;
    },
    /** When provided will enable support for persistent filters on the filter object */
    persistentFilterId?: string
    /** When true will always reload the summary row values on any data reload */
    alwaysReloadSummaryRow?: boolean;
    /**
     * When true will show 'No rows found...' message, can also be a string to override the default message.
     * If you need more custom markup you can set this to `true` and use noRowsFound slot.
     */
    noRowsFound?: boolean | string;
    /**
     * Will skip rows and columns indexing.  
     * WARNING: THIS WILL DISABLE ALL NAVIGATION FEATURES
     */
    skipElementIndexing?: boolean;
    /**
     * Adds 'Summary' tab under the column chooser allowing users to set custom aggregates on columns
     */
    userSummaryAggregates?: boolean;
    /** When set to true the flex styles on center columns container will be disabled, causing the right pinned columns to stick to the center columns  */
    disableRightPinnedOffset? :boolean;
    rowDragOptions?: { field?: string, step?: number, };
    rowDrag?: boolean;
    renderCellBorders?: boolean;
    /** Show layout actions in the status bar */
    showLayoutsAtBottom?: boolean;
    disableGridMenuPersist?: boolean;
    /** When true will exclude this grid from global click handler */
    disableSaveOnOutsideClicks?: boolean;
    /** Optional function that takes in a row and reutnrs true/false for if the multiselect checkbox should be disabled or not  */
    multiSelectDisabled?: (pRow: any) => boolean,
};
</script>

<script setup lang="ts">
import type { DataColumnOptions } from './types.ts';

import OGridContextMenu from './components.ContextMenu.vue';
import DataGridControl from './DataGridControl.ts';
import ODataGridHeader from './components.Header.vue';
import ODataGridStatusBar from './components.StatusBar.vue';
import OInfoItems from './components.InfoItems.vue';
import { Overlay } from 'o365-ui-components';
import { BodyWrapper } from './helpers.jsx';
import { InjectionKeys } from 'o365-utils';
import { parseColumnsFromVNodes } from './components.Columns.ts';
import useDataGridHover from './composables.Hover.ts';
import DataGridBodyRow from './components.BodyRow.vue';

import { useAsyncComponent, useErrorCapture, OErrorBoundry as ErrorBoundry } from 'o365-vue-utils';

import GridSidePanel from './components.SidePanel.vue';
import GridSidePanelTabs from './components.SidePanelTabs.vue';

import { ref, computed, watch, onMounted, useSlots, useAttrs, reactive, provide, onBeforeUnmount, nextTick, onActivated, inject } from 'vue';
import './extensions.Navigation.ts'; // Can't be imported in DataGrid.ts since its using it
console.log('cache issue')

const Paginator = useAsyncComponent('o365-data-components/DataObject.Paginator.vue', { importFn: () => import('o365-data-pagination').then(x => x.OPaginator)});
const ODataGridFooter = useAsyncComponent('o365-data-summary/GridFooter', { importFn: async () => {
    const summaryModule = await import('o365-data-summary');
    return summaryModule.ODataGridSummaryFooter;
}});
// const AfterPasteDialog = useAsyncComponent('./components.dialogs.AfterPasteDialog.vue', { importFn: () => import('./components.dialogs.AfterPasteDialog.vue') });

const props = withDefaults(defineProps<IProps>(), {
    hideNewRecords: raw => !!raw.isLookup,
    dynamicLoading: raw => !raw.disableDynamicLoading,
    activeRows: true,
    autoFilterDebounce: false,
    renderCellBorders: true,
    showLayoutsAtBottom: raw => !!raw.dataObject,
    gridMenuMinWidth: raw => {
        if (raw.initialMenuWidth != null) {
            return raw.initialMenuWidth.includes('%') ? '10%' : '200px';
        } else {
            return '200px';
        }
    },
    disableNavigation: raw => !!raw.skipElementIndexing
});

const emit = defineEmits<{
    (e: 'mounted'): void,
    (e: 'beforeCreate'): void,
    (e: 'beforePasteCommit', pOptions: {
        /** Items to be inserted */
        items: any[],
        /** Method that will be used to insert */
        type: 'oneByOne' | 'bulk'
        /** If type is `oneByOne` and this is set to true, then will only save one item at the time. */
        awaitOneByOne?: boolean
    }): void,
}>();

const slots = useSlots();
const attrs = useAttrs()

const masterGrid = inject(InjectionKeys.dataGridControlKey, null);

//--- DATA ---
const scrollerWidth = ref(500);

const showWidthScrollbar = ref(false);

let initialParsedColumns: DataColumnOptions[] = [];
if (props.columns) {
    initialParsedColumns = props.columns;
} else if (slots.default) {
    const vnodes = slots.default();
    initialParsedColumns = parseColumnsFromVNodes(vnodes);
} else if (props.dataObject) {
    initialParsedColumns = props.dataObject.fields.fields.map((field) => ({
        field: field.name,
        headerName: field.caption,
        editable: true,
    }));
}

const dataGridControl = ref<DataGridControl>(new DataGridControl(props, {
    id: attrs.id as string,
    masterGrid: masterGrid?.value?.id,
    isTable: true,
    columns: initialParsedColumns,
    slots: slots,
    emit: emit
}));
dataGridControl.value.isTable = true;

dataGridControl.value.postCreateInit();
if (props.eagerGridControl) {
    props.eagerGridControl(dataGridControl);
}

provide(InjectionKeys.dataGridControlKey, dataGridControl);

/**
 * @todo Remove all refrences
 * @deprecated Please use the dataColumns direclty from the control
 */
const dataColumns = computed(() => {
    return dataGridControl.value.dataColumns;
});

dataGridControl.value.dataColumns.setupWatchers(watch, dataGridControl.value.watchColumnChanges.bind(dataGridControl.value));
const showSummaryRow = computed(() => !dataColumns.value.columns.every(col => !col.summaryAggregate));

watch(dataColumns.value, (a, b) => { dataGridControl.value.watchColumnChanges(a, b) });

//--- DOM REFERENCES ---
const gridMenu = ref(null);
const containerRef = ref<HTMLElement|null>(null);
const viewportRef = ref<HTMLElement|null>(null);
const gridBody = ref<HTMLElement|null>(null);
const cellEditorRef = ref(null);
const contextMenuRef = ref(null);
const widthScrollerRef = ref(null);

//--- COMPUTED ---

const _data = computed(() => {
    if (props.dataObject) {
        return props.dataObject.data;
    } else {
        return props.data;
    }
});
// Need to rethink how indexes are handled in paged data
// const indexModifier = computed(() => {
    // if (props.dataObject.hasPagedData) {
        // return props.dataObject.pagedData.currentIndex
    // } else {
        // return 0;
    // }
// });

const viewPortWidth = computed(() => {
    setViewPortWidth();
    return scrollerWidth.value;
});

/** Indicates that the grid has non empty cardheader slot and should apply height fixes on it  */
const containerHasHeader = computed(() => {
    return (slots.cardheader !== undefined && slots.cardheader().length !== 0) || !!props.headerTitle;
});

const computedRowClass = computed(() => {
    switch (typeof props.rowClass) {
        case 'function':
            return props.rowClass;
        case 'object':
        case 'string':
            return () => props.rowClass;
        default:
            return () => '';
    }
}); // 🚩 Check usage
const computedRowStyle = computed(() => {
    switch (typeof props.rowStyle) {
        case 'function':
            return props.rowStyle;
        case 'object':
        case 'string':
            return () => props.rowStyle;
        default:
            return () => '';
    }
}); // 🚩 Check usage

const editMode = computed(() => {
    return dataGridControl.value.hasNavigation ? dataGridControl.value.navigation?.editMode : false;
}); // 🚩 Check usage

const activeCell = computed(() => {
    return dataGridControl.value.hasNavigation ? dataGridControl.value.navigation?.activeCellString : undefined;
});

const activeEditCell = computed(() => {
    return dataGridControl.value.navigation?.editMode ? activeCell.value : null;
});

//--- COMPOSABLES ---
if (props.dataObject) {
     // props.dataObject.hasPagedData = false; 
}
if (props.dataObject && props.dynamicLoading) {
    import('o365-data-pagination').then(() => {
        if (props.dataObject.recordSource.maxRecords <= 0) {
            props.dataObject.recordSource.maxRecords = 25;
        } else if (props.dataObject.recordSource.maxRecords > 500) {
            props.dataObject.recordSource.maxRecords = 500;
        }
        // props.dataObject.pagedData.disableStoragePointer = true;
        props.dataObject.pagedData.enable();
        if (props.dataObject.allowInsert && !props.hideNewRecords) {
            props.dataObject.batchData;
        }
    });
}

const girdUID = crypto.randomUUID();
dataGridControl.value.uid = girdUID;

function gridQuery(query, isBody = false) {
    if (isBody) {
        return `.grid-${girdUID}${query}`;
    } else {
        return `.grid-${girdUID} ${query}`;
    }
}

useDataGridHover({
    viewportRef: viewportRef,
    isTable: true
});


const hasFlexColumns = computed(() => {
    return dataGridControl.value.dataColumns.columns.some(x => x.flexWidth);
});

//--- FUNCTIONS ---
function setViewPortWidth() {
    if (containerRef && containerRef.value) {
        if (!containerRef.value.querySelector(gridQuery('.o365-grid-body', true))) { return; }
        scrollerWidth.value = containerRef.value.querySelector(gridQuery('.o365-grid-body', true)).clientWidth - dataGridControl.value.dataColumns.leftPinnedWidth - dataGridControl.value.dataColumns.rightPinnedWidth - 14//To do remove this if scroller n visible;
        if (hasFlexColumns.value) {
            let prevWidth = dataGridControl.value.dataColumns.unusedWidth;
            let unusedWidth = scrollerWidth.value - dataGridControl.value.dataColumns.centerWidth;
            unusedWidth = unusedWidth < 0 ? 0 : unusedWidth;
             if (prevWidth !== unusedWidth) {
                dataGridControl.value.dataColumns.unusedWidth = unusedWidth;
                dataGridControl.value.dataColumns.updateWidths();
             } 
        }

        const viewport = containerRef.value.querySelector(gridQuery('.o365-body-cols'));
        const widthScrollbarIsShown = showWidthScrollbar.value;
        showWidthScrollbar.value = scrollerWidth.value < viewport.scrollWidth;
        if (widthScrollbarIsShown !== showWidthScrollbar.value) {
            containerRef.value?.querySelectorAll<HTMLElement>(gridQuery('.o365-grid-container')).forEach(container => {
                if (!showWidthScrollbar.value && container) {
                    container.style.transform = 'translate(0px)';
                }
            });
        }
        window.requestAnimationFrame(() => {
            dataGridControl.value.updateWidthScrollState(containerRef.value?.querySelector(gridQuery('.o365-body-horizontal-scroll-viewport')));
        });
    }
}

let updateViewpoertDebounce = null;
function updateViewpoertWidth() {
    if (updateViewpoertDebounce) { window.clearTimeout(updateViewpoertDebounce); }
    updateViewpoertDebounce = window.setTimeout(() => {
        nextTick().then(() => {
            setViewPortWidth();
        });
        updateViewpoertDebounce = null;
    }, 10);
}

dataGridControl.value.updateViewport = updateViewpoertWidth;
dataGridControl.value.setViewPortWidth = setViewPortWidth;

/** Get the selection classes based on col and row indexes  */
function getSelectionClass(colIndex, rowIndex, container = 'G') {
    if (!dataGridControl.value.gridSelectionInterface?.selectionClassMap) { return; }

    const classMap = dataGridControl.value.gridSelectionInterface?.selectionClassMap[container]?.[rowIndex]?.[colIndex];
    let className;
    if (classMap) {
        className = classMap.join(' ');
    }

    if (activeCell.value && activeCell.value === `${container}_${colIndex}_${rowIndex}`) {
        className = (className ? className + ' ' : '') + 'o365-focus-cell';
    }

    return className;
}

/** Set the vertical scroll position of the viewport */
function setScrollPosition(pos) {
    if (viewportRef.value) {
        viewportRef.value.scrollTop = pos;
    }
}


/** Get the vertical scroll position of the viewport */
function getScrollPosition(){
    return viewportRef.value.scrollTop;
}

dataGridControl.value.getVerticalScrollViewport = () => viewportRef.value;

watch(() => dataGridControl.value.state.isLoading, (newValue, prevValue) => {
    if (prevValue && !newValue) {
        dataGridControl.value.clearSelection();
    }
});


// Force grid menu to be initially collapsed and add min-width to the grid on touch devices
let isTouch = false;
try {
    isTouch = matchMedia('screen and (pointer: coarse)')?.matches ?? false;
} catch (ex) {
    console.warn(ex);
}

//----------------------------------------------------------------------------------------------
// LIFECYCLE HOOKS
//----------------------------------------------------------------------------------------------


if (props.rowDrag || props.rowDragOptions) {
    dataGridControl.value.hasRowDrag = true;
}

onMounted(() => {
    dataGridControl.value.initializeContainer(containerRef.value, undefined, gridBody.value);
    if (dataGridControl.value.hasNavigation) {
       dataGridControl.value.navigation.cellEditorRef =  cellEditorRef;
       dataGridControl.value.navigation.contextMenuRef =  contextMenuRef;
    }
    window.setTimeout(() => {
        setViewPortWidth();
    }, 60);

    window.addEventListener('resize', () => {
        if (dataGridControl.value.menuTabs?.updateSidepanelWidth) {
            dataGridControl.value.menuTabs.updateSidepanelWidth();
        }
        setViewPortWidth();
    });

    emit('mounted');

    const modal = containerRef.value.closest('.modal');
    if (modal) {
        modal.addEventListener('shown.bs.modal', () => {
            setViewPortWidth();
        });
    }
    const tabPane = containerRef.value.closest('.tab-pane');
    if (tabPane && tabPane.id) {
        const tabEl = document.querySelector(`[data-bs-target="#${tabPane.id}"]`)
        tabEl?.addEventListener('shown.bs.tab', () => {
            setViewPortWidth();
        })
    }

    viewportRef.value?.addEventListener('click', (e) => {
        const target = e.target;
        const closest = target.closest('.o365-body-row');
        let rowIndex = closest?.getAttribute('data-o365-rowindex');
        const isInNewRecords = target.closest('[data-o365-container="N"]') != null;
        const row = isInNewRecords
            ? dataGridControl.value.dataObject?.batchData.data[rowIndex]
            : dataGridControl.value.dataObject?.data[rowIndex] ?? _data.value[rowIndex];
        if (row) {
            if (props.rowclickhandler) {
                props.rowclickhandler(row, e);
            } else {
                if (row.index != null) {
                    dataGridControl.value.setCurrentIndex(row.index);
                }

                // dataGridControl.value.setCurrentIndex(row.index ?? parseInt(rowIndex));
            }
        }
    });

    
    if (props.rowDrag || props.rowDragOptions) {
        import('./extensions.RowDrag.ts').then(() => {
            dataGridControl.value.rowDrag.enable(props.rowDragOptions)
        });
    }
});

onActivated(() => {
    const cachingTab = viewportRef.value?.closest('.o365-caching-tab');
    if (cachingTab) {
        const tabsEl = document.body.querySelector(`.o365-tabs-${cachingTab.dataset.tabsId}`)
        if (tabsEl) {
            const tabShownHandler = (e) => {
                dataGridControl.value.resetWidthScroll();
                tabsEl.removeEventListener('shown.bs.tab', tabShownHandler);
            };
            tabsEl.addEventListener('shown.bs.tab', tabShownHandler);
        } 
    }
});


onBeforeUnmount(() => {
    dataGridControl.value.isBeingUnmounted = true;

    dataGridControl.value.destroy();
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render grid content: ${dataGridControl.value?.id}`,
    errorRenderFunctionOptions: {
        type: 'card',
        uiMessage: 'An unhandled error has occurred when rendering the contents of this grid'
    }
});

if (props.loadDataObject) {
    if (!props.dataObject.state.isLoading && !props.dataObject.state.isLoaded) {
        props.dataObject.load();
    }
}

let prevEditorKey: string|null = null;
function updateEditorRef(pCmp: object|null, pKey: string) {
    if (pCmp == null) {
        if (prevEditorKey === pKey) {
            cellEditorRef.value = null;
        }
    } else {
        cellEditorRef.value = pCmp;
    }
    prevEditorKey = pKey;
}
dataGridControl.value.updateEditorRef = updateEditorRef;

const exposedRef =  ref({ dataColumns, setViewPortWidth, dataGridControl, setScrollPosition, getScrollPosition}); 
provide(InjectionKeys.dataGridRefKey, exposedRef);

const data = computed(() => {
    return _data.value.map(row => ({
        item: row,
        index: row?.index
    }));
});

const batchData = computed(() => {
    return props.dataObject.batchData.data.map(row => ({
        item: row,
        index: row?.index
    }));
});


const hasNewRecords = computed(() => {
    return dataGridControl.value.dataObject?.batchDataEnabled && dataGridControl.value.dataObject?.batchData.data.length > 0;;
});

const columnsWidth = computed(() => {
    return dataColumns.value.centerWidth + dataColumns.value.leftPinnedWidth;
});

function createNew() {
    props.dataObject.createNewAtTheEnd = true;
    // dataGridControl.value.enableAutoNewRow();
    props.dataObject.createNew();
}

const hasUncomittedRows = computed(() => {
    return hasNewRecords.value && props.dataObject.batchData.data.some(x => x.disableSaving);
});

const isCommitting = ref(false);
function savePastedRows(pBulk: boolean) {
    const pastedItems = props.dataObject.batchData.data.filter(item => item.disableSaving);
    isCommitting.value = true;
    if (pBulk) {
        dataGridControl.value.dataObject.recordSource.bulkCreateItems(pastedItems).then(() => {
            pastedItems.forEach(item => item.disableSaving = false);
        }).finally(() => {
            isCommitting.value = false;
        });
    } else {
        pastedItems.forEach(item => item.disableSaving = false);
        dataGridControl.value.dataObject.batchData.saveChanges(undefined, false).catch(ex => {
            pastedItems.forEach(item => {
                if (item.hasChanges) {
                    item.disableSaving = true;
                }
            });
        }).finally(() => {
            isCommitting.value = false;
        });
    }
}

async function clearPastedRows() {
    props.dataObject.batchData.data.filter(item => item.disableSaving).map(item => {
        return dataGridControl.value.dataObject.batchData.getInversedIndex(item.index);
    }).sort((a,b) => b - a).forEach(index => {
        dataGridControl.value.dataObject.batchData.storage.removeItem(index);
    });
    // data.value.filter(item => item.disableSaving).forEach(item => item.cancelChanges());
}

defineExpose({ dataColumns, setViewPortWidth, dataGridControl});

</script>
