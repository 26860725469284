<template>
    <div :id="chartID"></div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { getOrCreateProcedure } from 'o365-modules';
import { urlFilter } from 'o365-filterobject';

const props = defineProps({
    chartConfig: {
        type: String,
        required: true,
        default: null
    },
    procedure: {
        type: String,
        required: true,
        default: null
    },
    procedureParams: {
        type: Object,
        required: false,
        default: null
    },
    dashboardUrl: {
        type: String,
        required: false,
        default: null
    }
});

const emit = defineEmits(["loaded"]);

var loadDynamicChartProc = {};
const chartID = ref("chart_" + crypto.randomUUID());
let chart = null;

function load() {
    if (!props.procedure) return
    loadDynamicChartProc = getOrCreateProcedure({ id: props.procedure, procedureName: props.procedure, useAlert: false, useGroupedRequests: false });

    loadDynamicChartProc.execute(getProcedureParams()).then(async (response) => {
        let data;
        if (response.hasOwnProperty("Table")) {
            data = response.Table;
        } else {
            data = response;
        }

        const options = JSON.parse(props.chartConfig);
        const categoryColumn = options.categoryColumn.name;
        const isDistinct = options.categoryColumn.distinct;
        
        delete options.categoryColumn;
        delete options.seriesColumns;

        if (options.xAxis == null) { options.xAxis = {} };

        // If data will be datetime it must be converted to timestamp for format to be working.
        if (options.xAxis.type === 'datetime') {
            data.forEach(item => {
                let timeStamp = new Date(item[categoryColumn]).getTime();
                item[categoryColumn] = isNaN(timeStamp) ? 0 : timeStamp;
            })
        };

        handleSeriesColors(options.series);
        handleDataSorting(options.xAxis, data, categoryColumn);

        if (options.chart.type == 'pie') {
            for (let i = 0; i < data.length; i++) {
                options.series[0].data.push({
                    name: data[i][categoryColumn],
                    y: data[i][options.series[0].name]
                })
            }
            options.series[0].name = categoryColumn;
        } else {
            if (isDistinct) {
                let newSeriesNames: Array<string> = [];
                let categoryList: Array<string> = [];
                data.forEach((i) => {
                    if (newSeriesNames.filter(a => a == i[options.series[0].name]).length == 0) {
                        newSeriesNames.push(i[options.series[0].name]);
                    }
                    if (categoryList.filter(a => a == i[categoryColumn]).length == 0) {
                        categoryList.push(i[categoryColumn]);
                    }
                });

                let newSeries: Array<Object> = [];
                
                // Currently only first series is supported when distinct is used.
                newSeriesNames.forEach((seriesName) => {
                    let serieObject: Object = {
                        ...options.series[0],
                        name: seriesName,
                        data: []
                    }
                    
                    // If to use color coming from procedure
                    let colorToUse = false;
                    if (serieObject.useDefaultColor === false && serieObject.colorColumn) {
                        colorToUse = true;
                    }

                    categoryList.forEach((category) => {
                        let dataObj = data.find(row => row[categoryColumn] == category && row[options.series[0].name] == seriesName);

                        if (dataObj) {
                            let value = dataObj[options.series[0].valueColumn];

                            colorToUse = dataObj[serieObject.colorColumn];

                            if (options.series[0].dataType === 'percent') {
                                value = value * 100;
                            }

                            serieObject.data.push(value);
                        } else {
                            serieObject.data.push(null);
                        }
                    });

                    // Assign the color that was found
                    if (colorToUse && colorToUse !== true) {
                        serieObject.color = colorToUse;
                    }
                    newSeries.push(serieObject)
                });

                options.series = newSeries;
                options.xAxis.categories = categoryList;
            } else {
                options.series.forEach(seriesColumn => {
                    if (seriesColumn.valueColumn) {
                        seriesColumn.data = data.map(item => item[seriesColumn.valueColumn]);
                    } else {
                        seriesColumn.data = data.map(item => item[seriesColumn.name]);
                    }
                    if (seriesColumn.dataType === 'percent') {
                        seriesColumn.data = seriesColumn.data.map(value => value * 100);
                    }

                    if (seriesColumn.animation == null) {
                        seriesColumn.animation = {
                            duration: 500,
                            easing: 'easeOutBounce'
                        };
                    }
                });
                options.xAxis.categories = data.map(item => item[categoryColumn]);
            }
        }
        chart = Highcharts.chart(chartID.value, options);
        if (options.customURLFilterToRedirect) {
            data = await addFilterURL(data);
            chart.xAxis[0].update({ labels: { formatter: function (value) { return findResponsableIdCode(value, data); } } });
        }
        emit("loaded");
    }).catch((err) => {
        console.log(err)
    });

}
 function findResponsableIdCode(value, data) {
    var foundedID = data.find((obj) => obj.Responsible === value.value).URL
    return foundedID;

}
async function addFilterURL(data) {
    await Promise.all(
        data.map(async (x) => {
            x.URL = "<a target='_blank' href='/scope-items?filtermode=all&" +
                (await urlFilter.getCompressedUrlFilterString("[Responsible_ID] IN (" + x.Person_ID + ")", 'scope-library', 'dsScopeItems')) +
                "'>" + x.Responsible + "</a>";
        })
    );
    return data;
}
function getProcedureParams() {
    if (props.procedureParams) {
        let cleanedUpObj = {};
        Object.entries(props.procedureParams).forEach(([key, value]) => {
            if (value) {
                cleanedUpObj[key] = value
            } else {
                cleanedUpObj[key] = null

            }
        });
        return cleanedUpObj
    } else if (props.dashboardUrl) {
        let procParams = {};
        new URLSearchParams(props.dashboardUrl.split("?")[1]).forEach((value, key) => {
            if (key == "name") return; // to not pass the chart name as param
            procParams[key] = value;
        });
        return procParams
    } else {
        return {}
    }
}

function handleSeriesColors(series) {
    series.forEach(seriesColumn => {
        if (seriesColumn.useDefaultColor) {
            delete seriesColumn.color;
        }
    });
}

function handleDataSorting(xAxis, data, categoryColumn) {
    if (!xAxis.sortOrder) {
        return;
    }

    if (xAxis.sortOrder === 'asc') {
        data.sort((a, b) => (a[categoryColumn] > b[categoryColumn] ? 1 : -1));
    } else if (xAxis.sortOrder === 'desc') {
        data.sort((a, b) => (a[categoryColumn] < b[categoryColumn] ? 1 : -1));
    }
}

watch(() => props.procedureParams, () => {
    if (chartID.value) {
        load();
    }
}, { deep: true })

onMounted(() => {
    load();
})
</script>