/// <reference path="o365.pwa.declaration.shared.IndexedDBHandler.d.ts" />

/*******************************************************/
/* ---- IMPORTANT! Read before making any changes ---- */
/*******************************************************/
/* ----    This file is part of a set of files    ---- */
/* ----    Any changes here MUST be added to:     ---- */
/*******************************************************/
/* - o365.pwa.declaration.shared.IndexedDBHandler.d.ts */
/* - o365.pwa.modules.client.IndexedDBHandler.ts       */
/* - o365.pwa.modules.sw.IndexedDBHandler.ts           */
/*******************************************************/

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as IndexedDBHandlerModule from 'o365.pwa.declaration.shared.IndexedDBHandler.d.ts';

import type { App } from 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts';
import type { Database } from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';
import type { ObjectStore } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
import type { Index } from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';
import type { PWAState } from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { ServiceWorkerState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';
import type { AppResourceState } from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';
import type { User } from 'o365.pwa.declaration.shared.dexie.objectStores.User.d.ts';
import type { GlobalSetting } from 'o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts';
import type { UserDevice } from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';
import type { IUserDeviceOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';
import type { FileStoreRecord, IFileStoreRecordOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';
import type { IServiceWorkerStateOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { AppState } from 'o365.pwa.types.ts';
import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type { Table } from 'o365.pwa.declaration.sw.dexie.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { o365PWACore } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts')>("o365.pwa.modules.sw.dexie.databases.O365PWACore.ts");
    const { o365PWAGlobal } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts')>("o365.pwa.modules.sw.dexie.databases.O365PWAGlobal.ts");
    const { broadcastChannel } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.indexedDBBroadcastChannel.d.ts')>("o365.pwa.modules.sw.indexedDBBroadcastChannel.ts");

    const cache = <IndexedDBHandlerModule.IIndexedDBCache>{
        apps: new Map(),
        user: null,
        globalSetting: null
    };

    const instanceKey = crypto.randomUUID();

    broadcastChannel.onmessage = async (event: MessageEvent) => {
        const message = event.data;
        const messageType = message.type;
        const messageInstanceKey = message.instanceKey;

        if (messageInstanceKey === instanceKey) {
            return;
        }

        switch (messageType) {
            case 'App': {
                const { appId } = message;

                IndexedDBAppHandler.ifExistsRemoveAppFromCache(appId);
                break;
            }
            case 'Database': {
                const { appId, databaseId } = message;

                await IndexedDBDatabaseHandler.ifExistsRemoveDatabaseFromCache(appId, databaseId);
                break;
            }
            case 'ObjectStore': {
                const { appId, databaseId, objectStoreId } = message;

                await IndexedDBObjectStoreHandler.ifExistsRemoveObjectStoreFromCache(appId, databaseId, objectStoreId);
                break;
            }
            case 'Index': {
                const { appId, databaseId, objectStoreId, indexId } = message;

                await IndexedDBIndexHandler.ifExistsRemoveIndexFromCache(appId, databaseId, objectStoreId, indexId);
                break;
            }
            case 'PWAState': {
                const { appId } = message;

                await IndexedDBPWAStateHandler.ifExistsRemovePWAStateFromCache(appId);
                break;
            }
            case 'ServiceWorkerState': {
                const { appId } = message;

                await IndexedDBServiceWorkerStateHandler.ifExistsRemoveServiceWorkerStateFromCache(appId);
                break;
            }
            case 'SerivceWorkerScriptState': {
                const { appId, serviceWorkerScriptStateId } = message;

                await IndexedDBServiceWorkerScriptStateHandler.ifExistsRemoveServiceWorkerScriptStateFromCache(appId, serviceWorkerScriptStateId);
                break;
            }
            case 'AppResourceState': {
                const { appId, appResourceStateId } = message;

                await IndexedDBAppResourceStateHandler.ifExistsRemoveAppResourceStateFromCache(appId, appResourceStateId);
                break;
            }
            case 'User': {
                IndexedDBUserHandler.ifExistsRemoveUserFromCache();
                break;
            }
            case 'GlobalSetting': {
                IndexedDBGlobalSettingHandler.ifExistsRemoveGlobalSettingFromCache();
                break;
            }
        }
    };

    const IndexedDBAppHandler = <IndexedDBHandlerModule.IndexedDBAppHandler>{
        getApps: async (): Promise<Array<App>> => {
            const apps = await o365PWACore.instance.getApps();

            IndexedDBAppHandler.addAppsToCache(apps);

            return apps;
        },
        getApp: async (appId: string): Promise<App | null> => {
            let app = await IndexedDBAppHandler.getAppFromCache(appId);

            if (app === null) {
                app = await IndexedDBAppHandler.getAppFromIndexedDB(appId);
            }

            return app;
        },
        getAppCache: async (appId: string): Promise<IndexedDBHandlerModule.IAppCache | null> => {
            if (!cache.apps.has(appId)) {
                const app = await IndexedDBAppHandler.getAppFromIndexedDB(appId);

                if (app === null) {
                    return null;
                }

                IndexedDBAppHandler.addAppToCache(app);
            }

            return cache.apps.get(appId) ?? null;
        },
        getAppFromCache: async (appId: string): Promise<App | null> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (appCache) {
                return appCache.value;
            }

            return null;
        },
        getAppFromIndexedDB: async (appId: string): Promise<App | null> => {
            const app = await o365PWACore.instance.getApp(appId);

            if (app) {
                IndexedDBAppHandler.addAppToCache(app);
            }

            return app;
        },
        createApp: async (appId: string, title?: string, icon?: string, entrypoint?: string): Promise<App> => {
            await o365PWACore.instance.createApp(appId, title, icon, entrypoint);

            const app = await IndexedDBAppHandler.getApp(appId);

            if (app === null) {
                throw new Error('Failed to create app');
            }

            return app;
        },
        updateApp: async (app: App): Promise<void> => {
            await o365PWACore.instance.updateApp(app);

            IndexedDBAppHandler.ifExistsRemoveAppFromCache(app.id);

            broadcastChannel.postMessage({
                type: 'App',
                instanceKey: instanceKey,
                appId: app.id
            });
        },
        deleteApp: async (app: App): Promise<void> => {
            const appDB = await app.databases.getAll();

            for (let db of appDB) {
                await db.delete();
            }

            await o365PWACore.instance.deleteApp(app);
            IndexedDBAppHandler.removeAppFromCache(app);

            const appRetrieve = await IndexedDBAppHandler.getApp(app.id);
            if (!appRetrieve) {
                const pwaState = await IndexedDBHandler.getPWAStateFromIndexedDB(app.id);

                if (pwaState) {
                    await IndexedDBHandler.updatePWAState(Object.assign(pwaState, { appState: "ONLINE", isAppInstalled: false }))
                }
            }

            broadcastChannel.postMessage({
                type: 'App',
                instanceKey: instanceKey,
                appId: app.id
            });
        },
        addAppsToCache: (apps: Array<App>): void => {
            for (const app of apps) {
                IndexedDBAppHandler.addAppToCache(app);
            }
        },
        addAppToCache: (app: App): void => {
            if (cache.apps.has(app.id)) {
                cache.apps.get(app.id)!.value = app;
            } else {
                cache.apps.set(app.id, {
                    value: app,
                    databases: new Map(),
                    pwaState: null,
                    serviceWorkerState: null,
                });
            }
        },
        removeAppFromCache: (app: App): void => {
            cache.apps.delete(app.id);
        },
        ifExistsRemoveAppFromCache: (appId: string): void => {
            cache.apps.delete(appId);
        }
    };

    const IndexedDBDatabaseHandler = <IndexedDBHandlerModule.IndexedDBDatabaseHandler>{
        getDatabases: async (appId: string): Promise<Array<Database>> => {
            const databases = await o365PWACore.instance.getDatabases(appId);

            await IndexedDBDatabaseHandler.addDatabasesToCache(databases);

            return databases;
        },
        getDatabase: async (appId: string, databaseId: string): Promise<Database | null> => {
            return await IndexedDBDatabaseHandler.getDatabaseFromCache(appId, databaseId)
                ?? (await IndexedDBDatabaseHandler.getDatabaseFromIndexedDB(appId, databaseId));
        },
        getDatabaseCache: async (appId: string, databaseId: string): Promise<IndexedDBHandlerModule.IDatabaseCache | null> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (appCache === null) {
                return null;
            }

            if (!appCache.databases.has(databaseId)) {
                const database = await IndexedDBDatabaseHandler.getDatabaseFromIndexedDB(appId, databaseId);

                if (database === null) {
                    return null;
                }

                await IndexedDBDatabaseHandler.addDatabaseToCache(database);
            }

            return appCache.databases.get(databaseId) ?? null;
        },
        getDatabaseFromCache: async (appId: string, databaseId: string): Promise<Database | null> => {
            const databaseCache = await IndexedDBDatabaseHandler.getDatabaseCache(appId, databaseId);

            if (databaseCache) {
                return databaseCache.value;
            }

            return null;
        },
        getDatabaseFromIndexedDB: async (appId: string, databaseId: string): Promise<Database | null> => {
            const database = await o365PWACore.instance.getDatabase(appId, databaseId);

            if (database) {
                await IndexedDBDatabaseHandler.addDatabaseToCache(database);
            }

            return database;
        },
        createDatabase: async (appId: string, databaseId: string): Promise<Database> => {
            await o365PWACore.instance.createDatabase(appId, databaseId);

            const database = await IndexedDBDatabaseHandler.getDatabase(appId, databaseId);

            if (database === null) {
                throw new Error('Failed to create database');
            }

            return database;
        },
        updateDatabase: async (database: Database): Promise<void> => {
            await o365PWACore.instance.updateDatabase(database);

            await IndexedDBDatabaseHandler.ifExistsRemoveDatabaseFromCache(database.appId, database.id);

            broadcastChannel.postMessage({
                type: 'Database',
                instanceKey: instanceKey,
                appId: database.appId,
                databaseId: database.id
            });
        },
        deleteDatabase: async (database: Database): Promise<void> => {
            const objectStores = await o365PWACore.instance.getObjectStores(database.appId, database.id);

            for (let objStore of objectStores) {
                await objStore.delete();
            }

            await o365PWACore.instance.deleteDatabase(database);

            await IndexedDBDatabaseHandler.ifExistsRemoveDatabaseFromCache(database.appId, database.id);

            const dexie = await database.dexieInstance;
            await dexie.delete();

            broadcastChannel.postMessage({
                type: 'Database',
                instanceKey: instanceKey,
                appId: database.appId,
                databaseId: database.id
            });
        },
        addDatabasesToCache: async (databases: Array<Database>): Promise<void> => {
            for (const database of databases) {
                await IndexedDBDatabaseHandler.addDatabaseToCache(database);
            }
        },
        addDatabaseToCache: async (database: Database): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(database.appId);

            if (appCache === null) {
                throw new Error('Failed to find app cache');
            }

            if (appCache.databases.has(database.id)) {
                appCache.databases.get(database.id)!.value = database;
            } else {
                appCache.databases.set(database.id, {
                    value: database,
                    objectStores: new Map()
                });
            }
        },
        removeDatabaseFromCache: async (database: Database): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(database.appId);

            if (appCache === null) {
                throw new Error('Failed to find app cache');
            }

            appCache.databases.delete(database.id);
        },
        ifExistsRemoveDatabaseFromCache: async (appId: string, databaseId: string): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (!appCache) {
                return;
            }

            appCache.databases.delete(databaseId);
        }
    };

    const IndexedDBObjectStoreHandler = <IndexedDBHandlerModule.IndexedDBObjectStoreHandler>{
        getObjectStores: async (appId: string, databaseId: string): Promise<Array<ObjectStore>> => {
            const objectStores = await o365PWACore.instance.getObjectStores(appId, databaseId);

            await IndexedDBObjectStoreHandler.addObjectStoresToCache(objectStores);

            return objectStores;
        },
        getObjectStore: async (appId: string, databaseId: string, objectStoreId: string): Promise<ObjectStore | null> => {
            return await IndexedDBObjectStoreHandler.getObjectStoreFromCache(appId, databaseId, objectStoreId)
                ?? (await IndexedDBObjectStoreHandler.getObjectStoreFromIndexedDB(appId, databaseId, objectStoreId));
        },
        getObjectStoreCache: async (appId: string, databaseId: string, objectStoreId: string): Promise<IndexedDBHandlerModule.IObjectStoreCache | null> => {
            const databaseCache = await IndexedDBDatabaseHandler.getDatabaseCache(appId, databaseId);

            if (databaseCache === null) {
                return null;
            }

            if (!databaseCache.objectStores.has(objectStoreId)) {
                const objectStore = await IndexedDBObjectStoreHandler.getObjectStoreFromIndexedDB(appId, databaseId, objectStoreId);

                if (objectStore === null) {
                    return null;
                }

                await IndexedDBObjectStoreHandler.addObjectStoreToCache(objectStore);
            }

            return databaseCache.objectStores.get(objectStoreId) ?? null;
        },
        getObjectStoreFromCache: async (appId: string, databaseId: string, objectStoreId: string): Promise<ObjectStore | null> => {
            const objectStoreCache = await IndexedDBObjectStoreHandler.getObjectStoreCache(appId, databaseId, objectStoreId);

            if (objectStoreCache) {
                return objectStoreCache.value;
            }

            return null;
        },
        getObjectStoreFromIndexedDB: async (appId: string, databaseId: string, objectStoreId: string): Promise<ObjectStore | null> => {
            const objectStore = await o365PWACore.instance.getObjectStore(appId, databaseId, objectStoreId);

            if (objectStore) {
                await IndexedDBObjectStoreHandler.addObjectStoreToCache(objectStore);
            }

            return objectStore;
        },
        createObjectStore: async (appId: string, databaseId: string, objectStoreId: string, jsonDataVersion: number | null, fields: Array<string> | null): Promise<ObjectStore> => {
            await o365PWACore.instance.createObjectStore(appId, databaseId, objectStoreId, jsonDataVersion, fields);

            const objectStore = await IndexedDBObjectStoreHandler.getObjectStore(appId, databaseId, objectStoreId);

            if (objectStore === null) {
                throw new Error('Failed to create object store');
            }

            return objectStore;
        },
        updateObjectStore: async (objectStore: ObjectStore): Promise<void> => {
            await o365PWACore.instance.updateObjectStore(objectStore);

            await IndexedDBObjectStoreHandler.ifExistsRemoveObjectStoreFromCache(objectStore.appId, objectStore.databaseId, objectStore.id);

            broadcastChannel.postMessage({
                type: 'ObjectStore',
                instanceKey: instanceKey,
                appId: objectStore.appId,
                databaseId: objectStore.databaseId,
                objectStoreId: objectStore.id
            });
        },
        deleteObjectStore: async (objectStore: ObjectStore): Promise<void> => {
            const indexes = await objectStore.indexes.getAll();

            for (let index of indexes) {
                await index.delete();
            }

            await o365PWACore.instance.deleteObjectStore(objectStore);

            await IndexedDBObjectStoreHandler.ifExistsRemoveObjectStoreFromCache(objectStore.appId, objectStore.databaseId, objectStore.id);

            broadcastChannel.postMessage({
                type: 'ObjectStore',
                instanceKey: instanceKey,
                appId: objectStore.appId,
                databaseId: objectStore.databaseId,
                objectStoreId: objectStore.id
            });
        },
        addObjectStoresToCache: async (objectStores: Array<ObjectStore>): Promise<void> => {
            for (const objectStore of objectStores) {
                await IndexedDBObjectStoreHandler.addObjectStoreToCache(objectStore);
            }
        },
        addObjectStoreToCache: async (objectStore: ObjectStore): Promise<void> => {
            const databaseCache = await IndexedDBDatabaseHandler.getDatabaseCache(objectStore.appId, objectStore.databaseId);

            if (databaseCache === null) {
                throw new Error('Failed to find database cache');
            }

            if (databaseCache.objectStores.has(objectStore.id)) {
                databaseCache.objectStores.get(objectStore.id)!.value = objectStore;
            } else {
                databaseCache.objectStores.set(objectStore.id, {
                    value: objectStore,
                    indexes: new Map()
                });
            }
        },
        removeObjectStoreFromCache: async (objectStore: ObjectStore): Promise<void> => {
            const databaseCache = await IndexedDBDatabaseHandler.getDatabaseCache(objectStore.appId, objectStore.databaseId);

            if (databaseCache === null) {
                throw new Error('Failed to find database cache');
            }

            databaseCache.objectStores.delete(objectStore.id);
        },
        ifExistsRemoveObjectStoreFromCache: async (appId: string, databaseId: string, objectStoreId: string): Promise<void> => {
            const databaseCache = await IndexedDBDatabaseHandler.getDatabaseCache(appId, databaseId);

            if (!databaseCache) {
                return;
            }

            databaseCache.objectStores.delete(objectStoreId);
        }
    };

    const IndexedDBIndexHandler = <IndexedDBHandlerModule.IndexedDBIndexHandler>{
        getIndexes: async (appId: string, databaseId: string, objectStoreId: string): Promise<Array<Index>> => {
            const indexes = await o365PWACore.instance.getIndexes(appId, databaseId, objectStoreId);

            await IndexedDBIndexHandler.addIndexesToCache(indexes);

            return indexes;
        },
        getIndex: async (appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<Index | null> => {
            return await IndexedDBIndexHandler.getIndexFromCache(appId, databaseId, objectStoreId, indexId)
                ?? (await IndexedDBIndexHandler.getIndexFromIndexedDB(appId, databaseId, objectStoreId, indexId));
        },
        getIndexCache: async (appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<IndexedDBHandlerModule.IIndexCache | null> => {
            const objectStoreCache = await IndexedDBObjectStoreHandler.getObjectStoreCache(appId, databaseId, objectStoreId);

            if (objectStoreCache === null) {
                return null;
            }

            if (!objectStoreCache.indexes.has(indexId)) {
                const index = await IndexedDBIndexHandler.getIndexFromIndexedDB(appId, databaseId, objectStoreId, indexId);

                if (index === null) {
                    return null;
                }

                await IndexedDBIndexHandler.addIndexToCache(index);
            }

            return objectStoreCache.indexes.get(indexId) ?? null;
        },
        getIndexFromCache: async (appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<Index | null> => {
            const indexCache = await IndexedDBIndexHandler.getIndexCache(appId, databaseId, objectStoreId, indexId);

            if (indexCache) {
                return indexCache.value;
            }

            return null;
        },
        getIndexFromIndexedDB: async (appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<Index | null> => {
            const index = await o365PWACore.instance.getIndex(appId, databaseId, objectStoreId, indexId);

            if (index) {
                await IndexedDBIndexHandler.addIndexToCache(index);
            }

            return index;
        },
        createIndex: async (appId: string, databaseId: string, objectStoreId: string, indexId: string, keyPath: string | string[] | null, isPrimaryKey?: boolean, isUnique?: boolean, isMultiEntry?: boolean, isAutoIncrement?: boolean): Promise<Index> => {
            await o365PWACore.instance.createIndex(appId, databaseId, objectStoreId, indexId, keyPath, isPrimaryKey, isUnique, isMultiEntry, isAutoIncrement);

            const index = await IndexedDBIndexHandler.getIndex(appId, databaseId, objectStoreId, indexId);

            if (index === null) {
                throw new Error('Failed to create index');
            }

            return index;
        },
        updateIndex: async (index: Index): Promise<void> => {
            await o365PWACore.instance.updateIndex(index);

            await IndexedDBIndexHandler.ifExistsRemoveIndexFromCache(index.appId, index.databaseId, index.objectStoreId, index.id);

            broadcastChannel.postMessage({
                type: 'Index',
                instanceKey: instanceKey,
                appId: index.appId,
                databaseId: index.databaseId,
                objectStoreId: index.objectStoreId,
                indexId: index.id
            });
        },
        deleteIndex: async (index: Index): Promise<void> => {
            await o365PWACore.instance.deleteIndex(index);

            await IndexedDBIndexHandler.ifExistsRemoveIndexFromCache(index.appId, index.databaseId, index.objectStoreId, index.id);

            broadcastChannel.postMessage({
                type: 'Index',
                instanceKey: instanceKey,
                appId: index.appId,
                databaseId: index.databaseId,
                objectStoreId: index.objectStoreId,
                indexId: index.id
            });
        },
        addIndexesToCache: async (indexes: Array<Index>): Promise<void> => {
            for (const index of indexes) {
                await IndexedDBIndexHandler.addIndexToCache(index);
            }
        },
        addIndexToCache: async (index: Index): Promise<void> => {
            const objectStoreCache = await IndexedDBObjectStoreHandler.getObjectStoreCache(index.appId, index.databaseId, index.objectStoreId);

            if (objectStoreCache === null) {
                throw new Error('Failed to find object store cache');
            }

            if (objectStoreCache.indexes.has(index.id)) {
                objectStoreCache.indexes.get(index.id)!.value = index;
            } else {
                objectStoreCache.indexes.set(index.id, {
                    value: index
                });
            }
        },
        removeIndexFromCache: async (index: Index): Promise<void> => {
            const objectStoreCache = await IndexedDBObjectStoreHandler.getObjectStoreCache(index.appId, index.databaseId, index.objectStoreId);

            if (objectStoreCache === null) {
                throw new Error('Failed to find object store cache');
            }

            objectStoreCache.indexes.delete(index.id);
        },
        ifExistsRemoveIndexFromCache: async (appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<void> => {
            const objectStoreCache = await IndexedDBObjectStoreHandler.getObjectStoreCache(appId, databaseId, objectStoreId);

            if (!objectStoreCache) {
                return;
            }

            objectStoreCache.indexes.delete(indexId);
        }
    };

    const IndexedDBPWAStateHandler = <IndexedDBHandlerModule.IndexedDBPWAStateHandler>{
        getPWAState: async (appId: string): Promise<PWAState | null> => {
            let pwaState = await IndexedDBPWAStateHandler.getPWAStateFromCache(appId);

            if (pwaState === null) {
                pwaState = await IndexedDBPWAStateHandler.getPWAStateFromIndexedDB(appId);
            }

            return pwaState;
        },
        getPWAStateFromCache: async (appId: string): Promise<PWAState | null> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (appCache) {
                return appCache.pwaState;
            }

            return null;
        },
        getPWAStateFromIndexedDB: async (appId: string): Promise<PWAState | null> => {
            const pwaState = await o365PWACore.instance.getPwaState(appId);

            if (pwaState) {
                await IndexedDBPWAStateHandler.addPWAStateToCache(pwaState);
            }

            return pwaState;
        },
        createPWAState: async (appId: string, appState: AppState = 'OFFLINE', hasDatabaseConnection: boolean): Promise<PWAState> => {
            await o365PWACore.instance.createPwaState(appId, appState, hasDatabaseConnection);

            const pwaState = await IndexedDBPWAStateHandler.getPWAState(appId);

            if (pwaState === null) {
                throw new Error('Failed to create pwa state');
            }

            return pwaState;
        },
        updatePWAState: async (pwaState: PWAState): Promise<void> => {
            await o365PWACore.instance.updatePwaState(pwaState);

            await IndexedDBPWAStateHandler.ifExistsRemovePWAStateFromCache(pwaState.appId);

            broadcastChannel.postMessage({
                type: 'PWAState',
                instanceKey: instanceKey,
                appId: pwaState.appId
            });
        },
        deletePWAState: async (pwaState: PWAState): Promise<void> => {
            await o365PWACore.instance.deletePwaState(pwaState);

            await IndexedDBPWAStateHandler.removePWAStateFromCache(pwaState);

            broadcastChannel.postMessage({
                type: 'PWAState',
                instanceKey: instanceKey,
                appId: pwaState.appId
            });
        },
        addPWAStateToCache: async (pwaState: PWAState): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(pwaState.appId);

            if (appCache === null) {
                throw new Error('Failed to find app cache');
            }

            appCache.pwaState = pwaState
        },
        removePWAStateFromCache: async (pwaState: PWAState): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(pwaState.appId);

            if (appCache === null) {
                throw new Error('Failed to find app cache');
            }

            appCache.pwaState = null;
        },
        ifExistsRemovePWAStateFromCache: async (appId: string): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (!appCache) {
                return;
            }

            appCache.pwaState = null;
        }
    };

    const IndexedDBServiceWorkerStateHandler = <IndexedDBHandlerModule.IndexedDBServiceWorkerStateHandler>{
        getServiceWorkerState: async (appId: string): Promise<ServiceWorkerState | null> => {
            return await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateFromCache(appId)
                ?? (await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateFromIndexedDB(appId));
        },
        getServiceWorkerStateCache: async (appId: string): Promise<IndexedDBHandlerModule.IServiceWorkerStateCache | null> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (appCache === null) {
                return null;
            }

            if (appCache.serviceWorkerState === null) {
                const serviceWorkerState = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateFromIndexedDB(appId);

                if (serviceWorkerState === null) {
                    return null;
                }

                await IndexedDBServiceWorkerStateHandler.addServiceWorkerStateToCache(serviceWorkerState);
            }

            return appCache.serviceWorkerState;
        },
        getServiceWorkerStateFromCache: async (appId: string): Promise<ServiceWorkerState | null> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appId);

            if (serviceWorkerStateCache) {
                return serviceWorkerStateCache.value;
            }

            return null;
        },
        getServiceWorkerStateFromIndexedDB: async (appId: string): Promise<ServiceWorkerState | null> => {
            const serviceWorkerState = await o365PWACore.instance.getServiceWorkerState(appId);

            if (serviceWorkerState) {
                await IndexedDBServiceWorkerStateHandler.addServiceWorkerStateToCache(serviceWorkerState);
            }

            return serviceWorkerState;
        },
        createServiceWorkerState: async (options: IServiceWorkerStateOptions): Promise<ServiceWorkerState> => {
            await o365PWACore.instance.createServiceWorkerState(options);

            const serviceWorkerState = await IndexedDBServiceWorkerStateHandler.getServiceWorkerState(options.appId);

            if (serviceWorkerState === null) {
                throw new Error('Failed to create service worker state');
            }

            return serviceWorkerState;
        },
        updateServiceWorkerState: async (serviceWorkerState: ServiceWorkerState): Promise<void> => {
            await o365PWACore.instance.updateServiceWorkerState(serviceWorkerState);

            await IndexedDBServiceWorkerStateHandler.ifExistsRemoveServiceWorkerStateFromCache(serviceWorkerState.appId);

            broadcastChannel.postMessage({
                type: 'ServiceWorkerState',
                instanceKey: instanceKey,
                appId: serviceWorkerState.appId
            });
        },
        deleteServiceWorkerState: async (serviceWorkerState: ServiceWorkerState): Promise<void> => {
            await o365PWACore.instance.deleteServiceWorkerState(serviceWorkerState);

            await IndexedDBServiceWorkerStateHandler.removeServiceWorkerStateFromCache(serviceWorkerState);

            broadcastChannel.postMessage({
                type: 'ServiceWorkerState',
                instanceKey: instanceKey,
                appId: serviceWorkerState.appId
            });
        },
        addServiceWorkerStateToCache: async (serviceWorkerState: ServiceWorkerState): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(serviceWorkerState.appId);

            if (appCache === null) {
                throw new Error('Failed to load app cache');
            }

            appCache.serviceWorkerState = {
                value: serviceWorkerState,
                serviceWorkerScriptStates: new Map(),
                appResourceStates: new Map()
            };
        },
        removeServiceWorkerStateFromCache: async (pwaState: ServiceWorkerState): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(pwaState.appId);

            if (appCache === null) {
                throw new Error('Failed to load app cache');
            }

            appCache.serviceWorkerState = null;
        },
        ifExistsRemoveServiceWorkerStateFromCache: async (appId: string): Promise<void> => {
            const appCache = await IndexedDBAppHandler.getAppCache(appId);

            if (!appCache) {
                return;
            }

            appCache.serviceWorkerState = null;
        },
        clearServiceWorkerStateCache: (): void => {
            cache.apps.forEach((appCache) => {
                appCache.pwaState = null;
            });
        }
    };

    const IndexedDBServiceWorkerScriptStateHandler = <IndexedDBHandlerModule.IndexedDBServiceWorkerScriptStateHandler>{
        getServiceWorkerScriptStates: async (appId: string): Promise<Array<ServiceWorkerScriptState>> => {
            const serviceWorkerScriptStates = await o365PWACore.instance.getServiceWorkerScriptStates(appId);

            await IndexedDBServiceWorkerScriptStateHandler.addServiceWorkerScriptStatesToCache(serviceWorkerScriptStates);

            return serviceWorkerScriptStates;
        },
        getServiceWorkerScriptState: async (appId: string, serviceWorkerScriptStateId: string): Promise<ServiceWorkerScriptState | null> => {
            return await IndexedDBServiceWorkerScriptStateHandler.getServiceWorkerScriptStateFromCache(appId, serviceWorkerScriptStateId)
                ?? (await IndexedDBServiceWorkerScriptStateHandler.getServiceWorkerScriptStateFromIndexedDB(appId, serviceWorkerScriptStateId));
        },
        getServiceWorkerScriptStateCache: async (appId: string, serviceWorkerScriptStateId: string): Promise<IndexedDBHandlerModule.IServiceWorkerScriptStateCache | null> => {

            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appId);

            if (serviceWorkerStateCache === null) {
                return null;
            }

            if (!serviceWorkerStateCache.serviceWorkerScriptStates.has(serviceWorkerScriptStateId)) {
                const serviceWorkerScriptState = await IndexedDBServiceWorkerScriptStateHandler.getServiceWorkerScriptStateFromIndexedDB(appId, serviceWorkerScriptStateId);

                if (serviceWorkerScriptState === null) {
                    return null;
                }

                await IndexedDBServiceWorkerScriptStateHandler.addServiceWorkerScriptStateToCache(serviceWorkerScriptState);
            }

            return serviceWorkerStateCache.serviceWorkerScriptStates.get(serviceWorkerScriptStateId) ?? null;
        },
        getServiceWorkerScriptStateFromCache: async (appId: string, serviceWorkerScriptStateId: string): Promise<ServiceWorkerScriptState | null> => {
            const serviceWorkerScriptStateCache = await IndexedDBServiceWorkerScriptStateHandler.getServiceWorkerScriptStateCache(appId, serviceWorkerScriptStateId);

            if (serviceWorkerScriptStateCache) {
                return serviceWorkerScriptStateCache.value;
            }

            return null;
        },
        getServiceWorkerScriptStateFromIndexedDB: async (appId: string, serviceWorkerScriptStateId: string): Promise<ServiceWorkerScriptState | null> => {
            const serviceWorkerScriptState = await o365PWACore.instance.getServiceWorkerScriptState(appId, serviceWorkerScriptStateId);

            if (serviceWorkerScriptState) {
                await IndexedDBServiceWorkerScriptStateHandler.addServiceWorkerScriptStateToCache(serviceWorkerScriptState);
            }

            return serviceWorkerScriptState;
        },
        createServiceWorkerScriptState: async (appId: string, serviceWorkerScriptStateId: string, importmapEntry: IServiceWorkerImportMapEntry): Promise<ServiceWorkerScriptState> => {
            await o365PWACore.instance.createServiceWorkerScriptState(appId, serviceWorkerScriptStateId, importmapEntry);


            const serviceWorkerScriptState = await IndexedDBServiceWorkerScriptStateHandler.getServiceWorkerScriptState(appId, serviceWorkerScriptStateId);

            if (serviceWorkerScriptState === null) {
                throw new Error('Failed to create service worker script state');
            }

            return serviceWorkerScriptState;
        },
        updateServiceWorkerScriptState: async (serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void> => {
            await o365PWACore.instance.updateServiceWorkerScriptState(serviceWorkerScriptState);

            await IndexedDBServiceWorkerScriptStateHandler.ifExistsRemoveServiceWorkerScriptStateFromCache(serviceWorkerScriptState.appId, serviceWorkerScriptState.id);

            broadcastChannel.postMessage({
                type: 'SerivceWorkerScriptState',
                instanceKey: instanceKey,
                appId: serviceWorkerScriptState.appId,
                serviceWorkerScriptId: serviceWorkerScriptState.id
            });
        },
        deleteServiceWorkerScriptState: async (serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void> => {
            await o365PWACore.instance.deleteServiceWorkerScriptState(serviceWorkerScriptState);

            await IndexedDBServiceWorkerScriptStateHandler.removeServiceWorkerScriptStateFromCache(serviceWorkerScriptState);

            broadcastChannel.postMessage({
                type: 'SerivceWorkerScriptState',
                instanceKey: instanceKey,
                appId: serviceWorkerScriptState.appId,
                serviceWorkerScriptId: serviceWorkerScriptState.id
            });
        },
        addServiceWorkerScriptStatesToCache: async (serviceWorkerScriptStates: Array<ServiceWorkerScriptState>): Promise<void> => {
            for (const serviceWorkerScriptState of serviceWorkerScriptStates) {
                await IndexedDBServiceWorkerScriptStateHandler.addServiceWorkerScriptStateToCache(serviceWorkerScriptState);
            }
        },
        addServiceWorkerScriptStateToCache: async (serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(serviceWorkerScriptState.appId);

            if (serviceWorkerStateCache === null) {
                throw new Error('Failed to load service worker state cache');
            }

            if (serviceWorkerStateCache.serviceWorkerScriptStates.has(serviceWorkerScriptState.id)) {
                serviceWorkerStateCache.serviceWorkerScriptStates.get(serviceWorkerScriptState.id)!.value = serviceWorkerScriptState;
            } else {
                serviceWorkerStateCache.serviceWorkerScriptStates.set(serviceWorkerScriptState.id, {
                    value: serviceWorkerScriptState
                });
            }
        },
        removeServiceWorkerScriptStateFromCache: async (serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(serviceWorkerScriptState.appId);

            if (serviceWorkerStateCache === null) {
                throw new Error('Failed to load service worker state cache');
            }

            serviceWorkerStateCache.serviceWorkerScriptStates.delete(serviceWorkerScriptState.id);
        },
        ifExistsRemoveServiceWorkerScriptStateFromCache: async (appId: string, serviceWorkerScriptStateId: string): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appId);

            if (!serviceWorkerStateCache) {
                return;
            }

            serviceWorkerStateCache.serviceWorkerScriptStates.delete(serviceWorkerScriptStateId);
        }
    };

    const IndexedDBAppResourceStateHandler = <IndexedDBHandlerModule.IndexedDBAppResourceStateHandler>{
        getAppResourceStates: async (appId?: string): Promise<Array<AppResourceState>> => {
            const appResourceStates = await o365PWACore.instance.getAppResourceStates(appId);

            await IndexedDBAppResourceStateHandler.addAppResourceStatesToCache(appResourceStates);

            return appResourceStates;
        },
        getAppResourceState: async (appId: string, appResourceStateId: string): Promise<AppResourceState | null> => {
            return await IndexedDBAppResourceStateHandler.getAppResourceStateFromCache(appId, appResourceStateId)
                ?? (await IndexedDBAppResourceStateHandler.getAppResourceStateFromIndexedDB(appId, appResourceStateId));
        },
        getAppResourceStateCache: async (appId: string, appResourceStateId: string): Promise<IndexedDBHandlerModule.IAppResourceStateCache | null> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appId);

            if (serviceWorkerStateCache === null) {
                return null;
            }

            if (!serviceWorkerStateCache.appResourceStates.has(appResourceStateId)) {
                const appResourceState = await IndexedDBAppResourceStateHandler.getAppResourceStateFromIndexedDB(appId, appResourceStateId);

                if (appResourceState === null) {
                    return null;
                }

                await IndexedDBAppResourceStateHandler.addAppResourceStateToCache(appResourceState);
            }

            return serviceWorkerStateCache.appResourceStates.get(appResourceStateId) ?? null;
        },
        getAppResourceStateFromCache: async (appId: string, appResourceStateId: string): Promise<AppResourceState | null> => {
            const appResourceStateCache = await IndexedDBAppResourceStateHandler.getAppResourceStateCache(appId, appResourceStateId);

            if (appResourceStateCache) {
                return appResourceStateCache.value;
            }

            return null;
        },
        getAppResourceStateFromIndexedDB: async (appId: string, appResourceStateId: string): Promise<AppResourceState | null> => {
            const appResourceState = await o365PWACore.instance.getAppResourceState(appId, appResourceStateId);

            if (appResourceState) {
                await IndexedDBAppResourceStateHandler.addAppResourceStateToCache(appResourceState);
            }

            return appResourceState;
        },
        createAppResourceState: async (appId: string, appResourceStateId: string, relativeRoots: Array<string> = new Array(), urls: Array<string> = new Array(), scopes: Array<string> = new Array()): Promise<AppResourceState> => {
            await o365PWACore.instance.createAppResourceState(appId, appResourceStateId, relativeRoots, urls, scopes);

            const appResourceState = await IndexedDBAppResourceStateHandler.getAppResourceState(appId, appResourceStateId);

            if (appResourceState === null) {
                throw new Error('Failed to create app resource state');
            }

            return appResourceState;
        },
        updateAppResourceState: async (appResourceState: AppResourceState): Promise<void> => {
            await o365PWACore.instance.updateAppResourceState(appResourceState);

            await IndexedDBAppResourceStateHandler.ifExistsRemoveAppResourceStateFromCache(appResourceState.appId, appResourceState.id);

            broadcastChannel.postMessage({
                type: 'AppResourceState',
                instanceKey: instanceKey,
                appId: appResourceState.appId,
                appResourceId: appResourceState.id
            });
        },
        deleteAppResourceState: async (appResourceState: AppResourceState): Promise<void> => {
            await o365PWACore.instance.deleteAppResourceState(appResourceState);

            await IndexedDBAppResourceStateHandler.removeAppResourceStateFromCache(appResourceState);

            broadcastChannel.postMessage({
                type: 'AppResourceState',
                instanceKey: instanceKey,
                appId: appResourceState.appId,
                appResourceId: appResourceState.id
            });
        },
        addAppResourceStatesToCache: async (appResourceStates: Array<AppResourceState>): Promise<void> => {
            for (const appResourceState of appResourceStates) {
                await IndexedDBAppResourceStateHandler.addAppResourceStateToCache(appResourceState);
            }
        },
        addAppResourceStateToCache: async (appResourceState: AppResourceState): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appResourceState.appId);

            if (serviceWorkerStateCache === null) {
                throw new Error('Failed to load service worker state cache');
            }

            if (serviceWorkerStateCache.appResourceStates.has(appResourceState.id)) {
                serviceWorkerStateCache.appResourceStates.get(appResourceState.id)!.value = appResourceState;
            } else {
                serviceWorkerStateCache.appResourceStates.set(appResourceState.id, {
                    value: appResourceState
                });
            }
        },
        removeAppResourceStateFromCache: async (appResourceState: AppResourceState): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appResourceState.appId);

            if (serviceWorkerStateCache === null) {
                throw new Error('Failed to load service worker state cache');
            }

            serviceWorkerStateCache.appResourceStates.delete(appResourceState.id);
        },
        ifExistsRemoveAppResourceStateFromCache: async (appId: string, appResourceStateId: string): Promise<void> => {
            const serviceWorkerStateCache = await IndexedDBServiceWorkerStateHandler.getServiceWorkerStateCache(appId);

            if (!serviceWorkerStateCache) {
                return;
            }

            serviceWorkerStateCache.appResourceStates.delete(appResourceStateId);
        }
    };

    const IndexedDBUserHandler = <IndexedDBHandlerModule.IndexedDBUserHandler>{
        getUser: async (): Promise<User | null> => {
            return IndexedDBUserHandler.getUserFromCache()
                ?? (await IndexedDBUserHandler.getUserFromIndexedDB());
        },
        getUserFromCache: (): User | null => {
            return cache.user;
        },
        getUserFromIndexedDB: async (): Promise<User | null> => {
            const user = await o365PWACore.instance.getUser();

            if (user) {
                IndexedDBUserHandler.addUserToCache(user);
            }

            return user;
        },
        createUser: async (personId: number, userSession: any): Promise<User> => {
            await o365PWACore.instance.createUser(personId, userSession);

            const user = await IndexedDBUserHandler.getUser();

            if (user === null) {
                throw new Error('Failed to create user');
            }

            return user;
        },
        updateUser: async (user: User): Promise<void> => {
            await o365PWACore.instance.updateUser(user);

            IndexedDBUserHandler.ifExistsRemoveUserFromCache();

            broadcastChannel.postMessage({
                type: 'User',
                instanceKey: instanceKey,
            });
        },
        deleteUser: async (user: User): Promise<void> => {
            await o365PWACore.instance.deleteUser(user);

            IndexedDBUserHandler.removeUserFromCache();

            broadcastChannel.postMessage({
                type: 'User',
                instanceKey: instanceKey,
            });
        },
        addUserToCache: (user: User): void => {
            cache.user = user;
        },
        removeUserFromCache: (): void => {
            cache.user = null;
        },
        ifExistsRemoveUserFromCache: (): void => {
            cache.user = null;
        }
    };

    const IndexedDBGlobalSettingHandler = <IndexedDBHandlerModule.IndexedDBGlobalSettingHandler>{
        getGlobalSetting: async (): Promise<GlobalSetting | null> => {
            return IndexedDBGlobalSettingHandler.getGlobalSettingFromCache()
                ?? (await IndexedDBGlobalSettingHandler.getGlobalSettingFromIndexedDB());
        },
        getGlobalSettingFromCache: (): GlobalSetting | null => {
            return cache.globalSetting;
        },
        getGlobalSettingFromIndexedDB: async (): Promise<GlobalSetting | null> => {
            const globalSetting = await o365PWACore.instance.getGlobalSetting();

            if (globalSetting) {
                IndexedDBGlobalSettingHandler.addGlobalSettingToCache(globalSetting);
            }

            return globalSetting;
        },
        createGlobalSetting: async (cdnUrl: string): Promise<GlobalSetting> => {
            await o365PWACore.instance.createGlobalSetting(cdnUrl);

            const globalSetting = await IndexedDBGlobalSettingHandler.getGlobalSetting();

            if (globalSetting === null) {
                throw new Error('Failed to create GlobalSetting');
            }

            return globalSetting;
        },
        updateGlobalSetting: async (globalSetting: GlobalSetting): Promise<void> => {
            await o365PWACore.instance.updateGlobalSetting(globalSetting);

            IndexedDBGlobalSettingHandler.ifExistsRemoveGlobalSettingFromCache();

            broadcastChannel.postMessage({
                type: 'GlobalSetting',
                instanceKey: instanceKey,
            });
        },
        deleteGlobalSetting: async (globalSetting: GlobalSetting): Promise<void> => {
            await o365PWACore.instance.deleteGlobalSetting(globalSetting);

            IndexedDBGlobalSettingHandler.removeGlobalSettingFromCache();

            broadcastChannel.postMessage({
                type: 'GlobalSetting',
                instanceKey: instanceKey,
            });
        },
        addGlobalSettingToCache: (globalSetting: GlobalSetting): void => {
            cache.globalSetting = globalSetting;
        },
        removeGlobalSettingFromCache: (): void => {
            cache.globalSetting = null;
        },
        ifExistsRemoveGlobalSettingFromCache: (): void => {
            cache.globalSetting = null;
        }
    };

    const IndexedDBDexieHandler = <IndexedDBHandlerModule.IndexedDBDexieHandler>{
        getDataObjectDexieValues: (url: string | URL, dataObjectId: string) => {
            if (typeof url === 'string') {
                url = new URL(url);
            }

            const appId = url.pathname.split('/').pop();

            return {
                appId: appId,
                databaseId: 'DEFAULT',
                objectStoreId: dataObjectId
            };
        },
        getDexieInstance: async (appId: string, databaseId: string, objectStoreId: string): Promise<Table | null> => {
            const app = await IndexedDBAppHandler.getApp(appId);
            const database = await app?.databases[databaseId] ?? null;
            const dexieInstance = await database?.dexieInstance ?? null;

            if (dexieInstance === null) {
                return null;
            }

            const dexieTable = (dexieInstance as any)[objectStoreId] as Table;

            return dexieTable;
        }
    };

    const IndexedDBFileStoreHandler = <IndexedDBHandlerModule.IndexedDBFileStoreHandler>{
        retrieveFileStoreRecord: async (primKey: string, appId: string): Promise<FileStoreRecord | null> => {
            return await o365PWAGlobal.instance.retrieveFileStoreRecord(primKey, appId);
        },
        retrieveFileStoreRecords: async (primKeys: Array<string>, appIds: Array<string>): Promise<Array<FileStoreRecord | null>> => {
            return await o365PWAGlobal.instance.retrieveFileStoreRecords(primKeys, appIds);
        },
        createFileStoreRecord: async (fileStoreRecordOptions: IFileStoreRecordOptions) => {
            await o365PWAGlobal.instance.createFileStoreRecord(fileStoreRecordOptions);
        },
        bulkCreateFileStoreRecords: async (fileStoreRecordsOptions: Array<IFileStoreRecordOptions>) => {
            await o365PWAGlobal.instance.bulkCreateFileStoreRecords(fileStoreRecordsOptions);
        },
        updateFileStoreRecord: async (fileStoreRecord: FileStoreRecord) => {
            await o365PWAGlobal.instance.updateFileStoreRecord(fileStoreRecord);
        },
        bulkUpdateFileStoreRecords: async (fileStoreRecords: Array<FileStoreRecord>) => {
            await o365PWAGlobal.instance.bulkUpdateFileStoreRecords(fileStoreRecords);
        },
        deleteFileStoreRecord: async (fileStoreRecord: FileStoreRecord) => {
            await o365PWAGlobal.instance.destroyFileStoreRecord(fileStoreRecord.primKey, fileStoreRecord.appID);
        },
        bulkDeleteFileStoreRecords: async (fileStoreRecords: Array<FileStoreRecord>) => {
            const primKeys = [];
            const appIds = [];

            fileStoreRecords.forEach((fileStoreRecord, i) => {
                primKeys.push(fileStoreRecord.primKey);
                appIds.push(fileStoreRecord.appID);
            });

            await o365PWAGlobal.instance.bulkDestroyFileStoreRecords(primKeys, appIds);
        }
    };

    const IndexedDBUserDeviceHandler = <IndexedDBHandlerModule.IndexedDBUserDeviceHandler>{
        getUserDevice: async (): Promise<UserDevice | null> => {
            return await o365PWACore.instance.getUserDevice();
        },
        createUserDevice: async (userDevice: IUserDeviceOptions) => {
            await o365PWACore.instance.createUserDevice(userDevice);
        },
        updateUserDevice: async (userDevice: UserDevice) => {
            await o365PWACore.instance.updateUserDevice(userDevice);
        },
        deleteUserDevice: async (userDevice: UserDevice) => {
            await o365PWACore.instance.deleteUserDevice(userDevice);
        },
    };

    const IndexedDBHandler = <IndexedDBHandlerModule.IndexedDBHandler>{
        ...IndexedDBAppHandler,
        ...IndexedDBUserDeviceHandler,
        ...IndexedDBDatabaseHandler,
        ...IndexedDBObjectStoreHandler,
        ...IndexedDBIndexHandler,
        ...IndexedDBPWAStateHandler,
        ...IndexedDBServiceWorkerStateHandler,
        ...IndexedDBServiceWorkerScriptStateHandler,
        ...IndexedDBAppResourceStateHandler,
        ...IndexedDBUserHandler,
        ...IndexedDBGlobalSettingHandler,
        ...IndexedDBDexieHandler,
        ...IndexedDBFileStoreHandler
    };

    self.o365.exportScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>({ IndexedDBHandler });
})();
