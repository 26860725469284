import type { SortField } from './types.ts';

// This file is also used in the worker.
// DO NOT add any imports apart from types


/** Sort data on the client side */
export function sortData<T>(pOptions: { fields: SortField[] }, pData: Partial<T>[], fields: Record<string, SortField>, pItemKey?: string) {
    const sortOrder = pOptions.fields?.filter(x => x.sortOrder != null);
    if (sortOrder && sortOrder.length > 0) {
        sortOrder.sort((a, b) => a.sortOrder! - b.sortOrder!);
        pData.sort(combinedSortByKey(sortOrder, fields, pItemKey));

        // for (const item of sortOrder) {
        //     const field = fields[item.name];
        //     const fieldType = field?.type ?? 'string';
        //     pData.sort(sortByKey(item.name, item.sortDirection, fieldType, pItemKey));
        // }
    }

    return pData;
}

function combinedSortByKey(pSortOrder: SortField[], pFields: Record<string, SortField>, pItemKey?: string) {
    return (a: any, b: any) => {
        for (const item of pSortOrder) {
            const field = pFields[item.name];
            const fieldType = field?.type ?? 'string';
            const direction = item.sortDirection;

            const compare = sortByKey(item.name, direction, fieldType, pItemKey)(a, b);
            if (compare !== 0) {
                return compare;
            }
        }
        return 0;
    }
}

/** Get a sort function for a specific item key */
export function sortByKey(pKey: string, pOrder: 'asc' | 'desc' | null | undefined, pType: any, pItemKey?: string) {
    return (_a: any, _b: any) => {
        let a = pItemKey ? _a[pItemKey][pKey] : _a[pKey];
        let b = pItemKey ? _b[pItemKey][pKey] : _b[pKey];
        if (pType === 'string') {
            if (a && b) {
                if (a !== null) { a = a.toString().toUpperCase(); }
                if (b !== null) { b = b.toString().toUpperCase(); }
            } else if (a === undefined || a === '' || b === undefined || b === '') {
                if (a === b) {
                    return 0;
                } else if (a === undefined || a === '') {
                    return pOrder === 'asc' ? 1 : -1;
                } else if (b === undefined || b === '') {
                    return pOrder === 'asc' ? -1 : 1;
                }
            }
        } else if (['datetime', 'date'].includes(pType)) {
            a = new Date(a as string);
            b = new Date(b as string);
        }
        switch (pType) {
            case 'number':
                return pOrder === 'asc' ? (a - b) : (b - a);
            case 'string':
            case 'date':
            case 'datetime':
            case 'uniqueidentifier':
                return pOrder === 'asc' ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);
            default:
                return 0;
        }
    };
}

/**
 * Get promise object with resolve
 */
export function getPromise<T>(): { promise: Promise<T>, resolve: (value: T) => void } {
    let resolve = (_value: T) => { };

    const promise = new Promise<T>((res) => {
        resolve = res;
    });

    return { promise, resolve }
}

/**
 * Execute an async funtion with an abort signal
 * 
 */
export function withAbort<T>(pFn: (pAbortSignal?: AbortSignal) => Promise<T>, pAbortSignal: AbortSignal) {
    return new Promise((resolve, reject) => {
        if (pAbortSignal.aborted) {
            reject(new WorkerAbortError('Async function aborted'));
        }

        pFn(pAbortSignal).then(value => resolve(value));
        pAbortSignal.addEventListener('abort', () => {
            reject(new WorkerAbortError('Async function aborted'));
        });
    });
}

/**
 * Abort errror class used by NodeData worker
 */
export class WorkerAbortError extends Error {
    /** Skip vue error handlers */
    skipVueHandler = true;

    constructor(pMessage: string, pCause?: Error) {
        super(pMessage, {
            cause: pCause
        });
    }
}